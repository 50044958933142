import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { event as analyticsEvent } from '@deptno/gtag'

import queryString from 'query-string'
import { defineMessages, injectIntl } from 'react-intl'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Slider from '@material-ui/core/Slider'
import Tooltip from '@material-ui/core/Tooltip'
import Popover from '@material-ui/core/Popover'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import IconButton from '@material-ui/core/IconButton'
import ShareIcon from '@material-ui/icons/Share'
import FavoriteIcon from '@material-ui/icons/Favorite'
import CloseIcon from '@material-ui/icons/Close'
import FileCopyIcon from '@material-ui/icons/FileCopy'


import {
  EmailShareButton,
  EmailIcon,
  RedditShareButton,
  RedditIcon,
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";

import RandomTag from './components/RandomTag'
import * as data from './data'

import gamepadIcon from './images/gamepad_white.svg'
import ptIcon from './images/pt.svg'
import enIcon from './images/en.svg'
import chromeIcon from './images/chrome.svg'

const PUBLIC_URL = ''

const translateMessages = defineMessages({
    genre: {
        id: 'app.genre',
        defaultMessage: 'Genre'
    },
    mechanic: {
        id: 'app.mechanic',
        defaultMessage: 'Mechanic'
    },
    theme: {
        id: 'app.theme',
        defaultMessage: 'Theme'
    },
    genres: {
        id: 'app.genres',
        defaultMessage: 'Genres'
    },
    mechanics: {
        id: 'app.mechanics',
        defaultMessage: 'Mechanics'
    },
    themes: {
        id: 'app.themes',
        defaultMessage: 'Themes'
    },
    randomize: {
        id: 'app.randomize',
        defaultMessage: 'Randomize'
    },
    share: {
        id: 'app.share',
        defaultMessage: 'Share'
    },
    copy: {
        id: 'app.copy',
        defaultMessage: 'Copy'
    },
    title: {
        id: 'app.title',
        defaultMessage: 'Game Idea Generator'
    },
    by: {
        id: 'app.by',
        defaultMessage: 'by'
    },
    and: {
        id: 'app.and',
        defaultMessage: 'and'
    },
    descriptive: {
        id: 'app.descriptive',
        defaultMessage: 'Descriptive'
    },
    madeWith: {
        id: 'app.madeWith',
        defaultMessage: 'Made with'
    },
    mechanicSliderLabel: {
        id: 'app.mechanicSlider.label',
        defaultMessage: 'How many Mechanics?'
    },
    mechanicSliderSubtitle: {
        id: 'app.mechanicSlider.subtitle',
        defaultMessage: 'Use the slider to change the number of mechanics'
    },
    gameIdeaTitle: {
        id: 'app.gameIdea.title',
        defaultMessage: 'What about a'
    },
    howToFineTune: {
        id: 'app.howTo.fineTune',
        defaultMessage: 'You can click on each tag to randomize it and fine-tune your game idea!'
    },
    howToEnd: {
        id: 'app.howTo.end',
        defaultMessage: 'to generate a random set of tags.'
    },
    howToMiddle: {
        id: 'app.howTo.middle',
        defaultMessage: 'you\'ll want and click'
    },
    howToStart: {
        id: 'app.howTo.start',
        defaultMessage: 'Select how many'
    },
    howToDescription: {
        id: 'app.howTo.description',
        defaultMessage: 'You game idea is a combination of three tags:'
    },
    howToTitle: {
        id: 'app.howTo.title',
        defaultMessage: 'How to use?'
    },
    howToMiddle: {
        id: 'app.howTo.middle',
        defaultMessage: 'you\'ll want and click'
    },
})

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {'Copyright © '}
      <Link color="inherit" href="https://github.com/AndreMicheletti/game-idea-generator/blob/master/LICENSE">
        André Micheletti
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  card: {
    backgroundColor: "#333",
    padding: 30,
    paddingTop: 15,
    paddingBottom: 15,
  },
  formBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dataLine: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  descriptiveLine: {
    color: 'white',
    marginTop: theme.spacing(4)
  },
  instructionsBox: {
    marginTop: 28,
  },
  slider: {
    width: '12rem',
  },
  theme: {
    color: '#087b08',
    fontWeight: 500
  },
  genre: {
    color: '#e66d52',
    fontWeight: 500
  },
  mechanic: {
    color: '#3b28da',
    fontWeight: 500
  },
  dialogRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogCloseButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogTextBox: {
    padding: 8,
    backgroundColor: '#e8e8e8'
  },
  clickable: {
    cursor: 'pointer'
  },
  extensionPopup: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    margin: theme.spacing(4),
    padding: theme.spacing(2),
  }
}))


function App (props) {
  const { formatMessage } = props.intl
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [genres, setGenre] = React.useState([ data.getRandomGenre() ])
  const [mechanics, setMechanic] = React.useState([ data.getRandomMechanic([]), data.getRandomMechanic([]) ])
  const [themes, setTheme] = React.useState([ data.getRandomTheme() ])
  const [mechSlider, setSlider] = React.useState(2)
  const [openDialog, setOpen] = React.useState(false);
  const [showInstall, setShowInstall] = React.useState(true);

  const generateDescriptive = () => {
      const theme = themes.join(", ");
      const mech = mechanics.join(", ");
      const genre = genres.join(", ");
      if (props.lang === 'pt') {
        return `Um jogo ${genre} com mecânicas de ${mech} e temática ${theme}`
      } else {
        return `A ${genre} game with ${mech} mechanics and ${theme} thematic`
      }
  }

  const descriptiveResult = generateDescriptive();

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    analyticsEvent('share')
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const updArrayOnIndex = (arr, index, value) => {
    const aux = [...arr]
    aux[index] = value
    return [...aux]
  }

  const updArraySize = (arr, newSize, newDataCallback) => {
    const aux = [...arr]
    if (aux.length > newSize) {
      // remove item
      return aux.slice(0, newSize)
    } else {
      // add items
      [...Array(newSize - aux.length).keys()].forEach(index => {
        aux.push(newDataCallback())
      })
    }
    return [...aux]
  }

  const onRandomize = () => {
    analyticsEvent('purchase')
    setGenre(genres.map(_ => data.getRandomGenre()))
    setTheme(themes.map(_ => data.getRandomTheme()))
    setMechanic(data.getNRandomMechanics(mechSlider))
  }

  const handleSlider = (value) => {
    setSlider(value)
    setMechanic(updArraySize(mechanics, value, () => data.getRandomMechanic(mechanics)))
  }

  const handleCopy = async (event) => {
    setAnchorEl(event.currentTarget);
    await navigator.clipboard.writeText(descriptiveResult);
  }

  useEffect(() => {
    // check url querystring
    try {
      const parsed = queryString.parse(location.search)
      if (parsed && parsed.g) {
        const m = !!parsed.m ? parseInt(parsed.m) : 1
        const indexes = parsed.g.split('.')
        if (indexes.length !== 2 + m) {
          throw 'Not enough parameters. malformed querystring'
        }
        const pThemes = indexes.slice(0, 1)
        const pMechanics = indexes.slice(1, 1+m)
        const pGenres = indexes.slice(1+m, 2+m)
        setGenre(pGenres.map(i => data.getGenre(i)))
        setMechanic(pMechanics.map(i => data.getMechanic(i)))
        setTheme(pThemes.map(i => data.getTheme(i)))
        setSlider(m)
        analyticsEvent('view_promotion')
      }
    } catch (e) {
      console.warn(e)
      setGenre([data.getRandomGenre()])
      setMechanic([data.getRandomMechanic(mechanics), data.getRandomMechanic(mechanics)])
      setTheme([data.getRandomTheme()])
    }
  }, [])

  const renderGenreTags = () => {
    return genres.map((genre, index) => {
      return (
        <RandomTag
          key={`genre-${index}`}
          text={genre}
          color="#e66d52"
          tooltip={formatMessage(translateMessages.genre)}
          onClick={() => { setGenre(updArrayOnIndex(genres, index, data.getRandomGenre()))}}
        />
      )
    })
  }

  const renderMechanicTags = () => {
    return mechanics.map((mech, index) => {
      return (
        <RandomTag
          key={`mech-${index}`}
          text={mech}
          color="#3b28da"
          tooltip={formatMessage(translateMessages.mechanic)}
          onClick={() => { setMechanic(updArrayOnIndex(mechanics, index, data.getRandomMechanic(mechanics)))}}
        />
      )
    })
  }

  const renderThemeTags = () => {
    return themes.map((theme, index) => {
      return (
        <RandomTag
          key={`theme-${index}`}
          text={theme}
          color="#087b08"
          tooltip={formatMessage(translateMessages.theme)}
          onClick={() => { setTheme(updArrayOnIndex(themes, index, data.getRandomTheme()))}}
        />
      )
    })
  }

  const changeLang = (toLang) => {
    sessionStorage.setItem('lang', toLang)
    document.location.reload()
  }

  const protocol = 'https'
  const hostname = 'gameideagenerator.online'
  const shareUrl = `${protocol}://${hostname}/${data.buildUrl(genres, themes, mechanics, mechSlider)}`
  const shareTitle = 'Check out this game idea'
  const shareMessage = `${descriptiveResult}. Generated by`

  return (
    <div className={classes.root}>

      <AppBar position="relative">
        <Toolbar className={classes.toolbar}>
            <div className="flex flex-row flex-shrink items-center">
              <img src={gamepadIcon} className="md:h-12 md:w-12 w-8 h-8 mr-2" alt="logo" />
              <a href="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                <span className="lg:text-3xl sm:text-lg">
                  {formatMessage(translateMessages.title)}
                </span>
              </a>
            </div>
            <div className="flex flex-row flex-grow items-center justify-end">
                <div className={classes.clickable} onClick={() => changeLang('pt')}>
                    <img src={ptIcon} width="35" height="35"/>
                </div>
                <div style={{ width: 10 }}></div>
                <div className={classes.clickable} onClick={() => changeLang('en')}>
                    <img src={enIcon} width="35" height="35"/>
                </div>
            </div>
        </Toolbar>
      </AppBar>

      <Dialog open={openDialog}>
        <DialogTitle className={classes.dialogRoot}>
          <Typography component="p" variant="h5">{formatMessage(translateMessages.share)}</Typography>
          <IconButton aria-label="close" className={classes.dialogCloseButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogTextBox}>
            <Typography variant="subtitle1" style={{ fontWeight: 'normal' }}>
              {shareUrl}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <EmailShareButton url={shareUrl} title={shareTitle} body={shareMessage}>
            <EmailIcon round />
          </EmailShareButton>
          <FacebookShareButton url={shareUrl} quote={shareMessage}>
            <FacebookIcon round />
          </FacebookShareButton>
          <RedditShareButton url={shareUrl} title={shareMessage}>
            <RedditIcon round />
          </RedditShareButton>
          <WhatsappShareButton url={shareUrl} title={shareMessage}>
            <WhatsappIcon round />
          </WhatsappShareButton>
          <TelegramShareButton url={shareUrl} title={shareMessage}>
            <TelegramIcon round />
          </TelegramShareButton>
          <TwitterShareButton url={shareUrl} title={shareMessage}>
            <TwitterIcon round />
          </TwitterShareButton>
        </DialogActions>
      </Dialog>

      <main>
        <Container component="main" className={classes.main} maxWidth="md">

          <Box my={4} className={classes.formBox}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 20 }}>
              <Typography variant="h5" id="discrete-slider">
                {formatMessage(translateMessages.mechanicSliderLabel)}
              </Typography>
              <span className="text-center">
                {formatMessage(translateMessages.mechanicSliderSubtitle)}
              </span>
              <Slider
                className={classes.slider}
                defaultValue={1}
                value={mechSlider}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={1}
                marks={[
                  { value: 1, label: '1' },
                  { value: 2, label: '2' },
                  { value: 3, label: '3' },
                ]}
                min={1}
                max={3}
                onChangeCommitted={(_, value) => { handleSlider(value)}}
              />
            </div>
            <Button variant="contained" color="primary" onClick={() => onRandomize()}>
              {formatMessage(translateMessages.randomize)}
            </Button>
          </Box>

          <Card className={classes.card}>
            <CardHeader
              title={(
                <span className="text-white md:text-4xl text-2xl">
                  {formatMessage(translateMessages.gameIdeaTitle)}
                </span>
              )}
            />
            <CardContent>
              <div className="flex flex-col flex-shrink text-center md:flex-row md:flex-wrap">
                {renderThemeTags()}
                {renderMechanicTags()}
                {renderGenreTags()}
              </div>
              <div className={classes.descriptiveLine}>
                  <span className="text-white md:text-4xl text-2xl">
                    {formatMessage(translateMessages.descriptive)}
                  </span>
                  <div style={{ height: 8 }} />
                  <div className="text-lg">
                      {descriptiveResult}
                  </div>
              </div>
            </CardContent>
            <CardActions>
              <Tooltip title={formatMessage(translateMessages.share)} aria-label="share">
                <IconButton aria-label="share" color="primary" onClick={() => handleOpen()}>
                  <ShareIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={formatMessage(translateMessages.copy)} aria-label="copy">
                <IconButton aria-label="copy" color="primary" onClick={handleCopy}>
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            </CardActions>
          </Card>

          <Container maxWidth="sm" className={classes.instructionsBox}>
            <Typography variant="h4">
                            {formatMessage(translateMessages.howToTitle)}
            </Typography>
            <Typography variant="body1" style={{ marginTop: 3 }}>
                            {formatMessage(translateMessages.howToDescription)}
              <span className={classes.theme}> {formatMessage(translateMessages.theme)}</span>, 
              <span className={classes.mechanic}> {formatMessage(translateMessages.mechanics)}</span> {formatMessage(translateMessages.and)} 
              <span className={classes.genre}> {formatMessage(translateMessages.genre)}</span>
            </Typography>
            <br />
            <Typography variant="body1">
              
                            {formatMessage(translateMessages.howToStart)} <span className={classes.mechanic}>{" "}{formatMessage(translateMessages.mechanics)}</span> 
                            {" "} {formatMessage(translateMessages.howToMiddle)}
              <Typography color="primary" variant="button">
               {" "} {formatMessage(translateMessages.randomize)} {" "}
              </Typography>
                            {formatMessage(translateMessages.howToEnd)}
               
            </Typography>
            <br />
            <Typography variant="body1">
                            {formatMessage(translateMessages.howToFineTune)}
            </Typography>
          </Container>

        </Container>
      </main>

      <Popover
        id="simple-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Typography style={{ padding: 5 }}>Copied to clipboard.</Typography>
      </Popover>

      <footer className={classes.footer}>
        <Container maxWidth="sm" className={classes.footerContainer}>
          <Typography variant="body1">
                {formatMessage(translateMessages.madeWith)}
                <FavoriteIcon color="error" />
                {formatMessage(translateMessages.by)}
                <Link href="https://github.com/AndreMicheletti" target="_blank">{" "}AndreMicheletti</Link>
            </Typography>
          <Copyright />
        </Container>
      </footer>

      {/* {showInstall && (
          <div style={{ position: 'fixed', bottom: 0, left: 0 }}>
            <Paper className={classes.extensionPopup}>
                <img src={chromeIcon} alt="google-chrome-logo" width="80" height="80" style={{ marginRight: 10 }} />
                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '1.4em', justifyContent: 'space-around' }}>
                    <div>
                        Install the 
                        <strong>
                            &nbsp;Game Idea Generator chrome extension?
                        </strong>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <Button onClick={() => setShowInstall(false)}>
                            No, thanks
                        </Button>
                        <div style={{ width: 30 }} />
                        <a href="https://chrome.google.com/webstore/detail/game-idea-generator/aoeolgodbphjghklpaiapijnblbaoiod" target="_blank">
                            <Button variant="contained" color="primary" component="span">
                                Yes, install
                            </Button>
                        </a>
                    </div>
                </div>
            </Paper>
        </div>
      )} */}
    </div>
  )
}

export default injectIntl(App)
